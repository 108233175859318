import React, { useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { PerioPathChartVerticalGridLinesAndCharts } from "./PerioPathChartVerticalGridLinesAndCharts";
import { PerioPathYAxis } from "./PerioPathYAxis";
import { PerioPathChartHorizonalLimitLines } from "./PerioPathChartHorizonalLimitLines";
import PerioPathLegend from "./PerioPathLegend";
import { PerioPathBarChartProps } from "../componentProps/PerioPathChartProps";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	PointElement,
	LineElement,
	Tooltip,
	LogarithmicScale,
	LinearScale,
	BubbleDataPoint,
	ChartTypeRegistry,
	Point,
	TooltipModel,
} from "chart.js";
import { NumberHelper } from "../utils/NumberHelper";
import {
	perioPathLimit,
	perioPathSeriesColors1,
	perioPathSeriesColors2,
	perioPathSeriesColors3,
	perioPathSeriesColors4,
} from "../constants";
import { PerioPathDataPointNew } from "../models/PerioPathDataPointNew";

ChartJS.register(
	CategoryScale,
	PointElement,
	LineElement,
	Tooltip,
	LogarithmicScale,
	LinearScale,
);

export const PerioPathChart: React.FC<PerioPathBarChartProps> = ({ data }) => {
	const generateSampleData = useCallback((): PerioPathDataPointNew[] => {
		return [
			{
				date: "09/20",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},

			{
				date: "10/20",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "11/20",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "12/20",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "01/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "02/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "03/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "04/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "05/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "06/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
			{
				date: "07/21",
				value: Math.pow(10, NumberHelper.getRandomArbitrary(2, 4.3)),
			},
		];
	}, []);

	const getTooltipTitle = useCallback((color: string): string => {
		switch (color) {
			case perioPathSeriesColors1:
				return "Campylobactor Rectus";
			case perioPathSeriesColors2:
				return "Campylobactor Rectus";
			case perioPathSeriesColors3:
				return "Campylobactor Rectus";
			case perioPathSeriesColors4:
				return "Campylobactor Rectus";
			default:
				return "Campylobactor Rectus";
		}
	}, []);

	const getTooltipBody = useCallback((color: string): string => {
		switch (color) {
			case perioPathSeriesColors1:
				return "Moderate-Risk Anaerobic Pathogens Moderately associated with chronic periodontitis and risk of disease progression.";
			case perioPathSeriesColors2:
				return "Moderate-Risk Anaerobic Pathogens Moderately associated with chronic periodontitis and risk of disease progression.";
			case perioPathSeriesColors3:
				return "Moderate-Risk Anaerobic Pathogens Moderately associated with chronic periodontitis and risk of disease progression.";
			case perioPathSeriesColors4:
				return "Moderate-Risk Anaerobic Pathogens Moderately associated with chronic periodontitis and risk of disease progression.";
			default:
				return "Moderate-Risk Anaerobic Pathogens Moderately associated with chronic periodontitis and risk of disease progression.";
		}
	}, []);

	const getTooltipRiskText = useCallback((value: number): string => {
		return "Your Score is High";
	}, []);

	const createTooltip = useCallback(
		(context: {
			chart: ChartJS<
				keyof ChartTypeRegistry,
				(number | Point | [number, number] | BubbleDataPoint | null)[],
				unknown
			>;
			tooltip: TooltipModel<"line">;
		}) => {
			// Tooltip Element
			let tooltipEl = document.getElementById("chartjs-tooltip");

			// Create element on first render
			if (!tooltipEl) {
				tooltipEl = document.createElement("div");
				tooltipEl.id = "chartjs-tooltip";
				tooltipEl.innerHTML = "<div></div>";
				document.body.appendChild(tooltipEl);
			}

			// Hide if no tooltip
			const tooltipModel = context.tooltip;
			if (tooltipModel.opacity === 0) {
				tooltipEl.style.opacity = "0";
				return;
			}

			// Set caret Position
			tooltipEl.classList.remove("above", "below", "no-transform");
			if (tooltipModel.yAlign) {
				tooltipEl.classList.add(tooltipModel.yAlign);
			} else {
				tooltipEl.classList.add("no-transform");
			}

			function getBody(bodyItem: {
				before: string[];
				lines: string[];
				after: string[];
			}) {
				return bodyItem.lines;
			}

			// Set body and text
			if (tooltipModel.body) {
				const titleLines = tooltipModel.title || [];
				const bodyLines = tooltipModel.body.map(getBody);

				// Create the title
				let color = tooltipModel.labelColors[0].backgroundColor.toString();
				let fontFamily = "font-family: 'Poppins', sans-serif";
				let innerHtml = "<div>";
				titleLines.forEach(function (_) {
					let style = `${fontFamily}; font-weight: 600; font-size: 16px; text-align: center;`;
					innerHtml += `<h2 style="${style}">${getTooltipTitle(color)}</h2>`;
				});

				// Create the divider
				innerHtml += `<div style="height: 3px; width: 100%; background-color: ${color}; border-radius: 4px; margin-bottom: 10px"></div>`;
				innerHtml += "</div>";

				// Create the body
				innerHtml += "<div>";
				bodyLines.forEach(function (body, i) {
					const colors = tooltipModel.labelColors[i];
					const graphValue = body[0].split(":")[1];
					let bodyStyle = `${fontFamily}; margin-bottom: -20px; font-style: 14px;`;
					let graphValueStyle = `${fontFamily}; text-align: center; font-weight: 500; font-size: 40px; margin-bottom: -10px;`;
					let riskTextStyle = `${fontFamily}; text-align: center; font-weight: 600; font-size: 16px;`;

					innerHtml += `<p style="${bodyStyle}">${getTooltipBody(
						colors.backgroundColor.toString(),
					)}</p>`;
					innerHtml += `<p style="${graphValueStyle}">${graphValue}</p>`;
					innerHtml += `<p style="${riskTextStyle}">${getTooltipRiskText(
						parseFloat(graphValue),
					)}</p>`;
				});
				innerHtml += "</div>";

				let divRoot = tooltipEl.querySelector("div");
				if (divRoot !== null) {
					divRoot.innerHTML = innerHtml;
				}
			}

			const position = context.chart.canvas.getBoundingClientRect();

			// Styling
			tooltipEl.style.opacity = "1";
			tooltipEl.style.backgroundColor = "#ffffff";
			tooltipEl.style.width = "300px";
			tooltipEl.style.height = "300px";
			tooltipEl.style.borderRadius = "8px";
			tooltipEl.style.boxShadow = "0px 7px 29px 0px rgba(100, 100, 111, 0.2)";
			tooltipEl.style.position = "absolute";
			tooltipEl.style.zIndex = "99";
			tooltipEl.style.transition = "all 0.2s ease-out";
			tooltipEl.style.left =
				position.left + window.scrollX + tooltipModel.caretX + "px";
			tooltipEl.style.top =
				position.top + window.scrollY + tooltipModel.caretY + "px";
			tooltipEl.style.font = "'Poppins', sans-serif";
			tooltipEl.style.padding = "15px 25px";
			tooltipEl.style.pointerEvents = "none";
		},
		[getTooltipBody, getTooltipTitle],
	);

	const chartPadding = 572 / data.length;
	const widthAndLeftStyles: React.CSSProperties = {
		position: "absolute",
		width: "89.6%",
		left: "5.2%",
	};

	return (
		<Box sx={{ display: "flex", height: "600px" }}>
			<Box width={"45px"} />
			<PerioPathLegend />
			<Box width="45px" />
			<PerioPathYAxis />
			<Box width="20px" />
			<Box
				sx={{
					position: "relative",
					border: "1.5px solid #DCDCDC",
					borderRadius: "11.286px",
					width: "100%",
					padding: `0 ${chartPadding}px`,
				}}
			>
				<Divider
					sx={{
						borderColor: "#DCDCDC",
						position: "absolute",
						bottom: "113px",
						left: 0,
						width: "100%",
					}}
				/>
				<PerioPathChartHorizonalLimitLines sx={{ ...widthAndLeftStyles }} />
				<PerioPathChartVerticalGridLinesAndCharts
					sx={{ ...widthAndLeftStyles }}
					data={data}
				/>
				<Line
					style={{
						position: "relative",
						zIndex: 9,
						height: "494px",
						width: "900px",
					}}
					options={{
						plugins: {
							tooltip: {
								enabled: false,
								external: createTooltip,
							},
						},
						elements: {
							point: {
								radius: 5,
							},
						},
						responsive: true,
						scales: {
							y: {
								display: false,
								type: "logarithmic",
								min: 1,
								max: perioPathLimit,
								ticks: {
									autoSkip: false,
									maxTicksLimit: 7,
									callback: (value, index) => {
										if (index === 0) {
											return "0";
										} else if (
											value === 10 ||
											value === 100 ||
											value === 1000 ||
											value === 10000 ||
											value === 100000 ||
											value === 1000000 ||
											value === perioPathLimit
										) {
											return value;
										}
									},
								},
							},
							x: {
								display: false,
							},
						},
					}}
					data={{
						labels: generateSampleData().map((data) => data.date),
						datasets: [
							{
								label: "Aa",
								data: generateSampleData().map((data) => data.value),
								borderColor: perioPathSeriesColors1,
								backgroundColor: perioPathSeriesColors1,
								tension: 0.4,
							},
							{
								label: "Pg",
								data: generateSampleData().map((data) => data.value),
								borderColor: perioPathSeriesColors2,
								backgroundColor: perioPathSeriesColors2,
								tension: 0.4,
							},
							{
								label: "Tf",
								data: generateSampleData().map((data) => data.value),
								borderColor: perioPathSeriesColors3,
								backgroundColor: perioPathSeriesColors3,
								tension: 0.4,
							},
							{
								label: "Td",
								data: generateSampleData().map((data) => data.value),
								borderColor: perioPathSeriesColors4,
								backgroundColor: perioPathSeriesColors4,
								tension: 0.4,
							},
						],
					}}
				/>
			</Box>
		</Box>
	);
};
