import { MyPerioPathTopBar } from "./MyPerioPathTopBar";
import { RoundedContainer } from "./RoundedContainer";
import { Box, Typography } from "@mui/material";
import { PerioPathChart } from "./PerioPathChart";
import { MyPerioPathProps } from "../componentProps/MyPerioPathProps";
import { Center } from "./Center";
import { InfoModal } from "./InfoModal";
import ModalVirusImage from "../assets/images/modal-virus.png";
import { useState } from "react";

export const MyPerioPath: React.FC<MyPerioPathProps> = ({ data }) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	return (
		<RoundedContainer onMoreButtonClicked={() => setShowModal(!showModal)}>
			<InfoModal show={showModal}>
				<Typography
					variant="h3"
					sx={{ textAlign: "center", fontWeight: 700, fontSize: "1.1rem" }}
				>
					Did You Know?
				</Typography>
				<Box height="5px" />
				<Typography
					sx={{ textAlign: "center", lineHeight: "16px", fontWeight: 500 }}
				>
					There may be harmful bacteria in your mouth that travel throughout the
					body that may contribute to systemic disease.
				</Typography>
				<Box height="15px" />
				<Center>
					<img src={ModalVirusImage} alt="Virus" />
				</Center>
				<Box height="20px" />
				<Typography sx={{ textAlign: "center", lineHeight: "16px" }}>
					Salivary diagnostic testing measures the levels of bad bacteria and
					<br />
					identify your individual risk factors.
				</Typography>
				<Box height="7px" />
				<Typography sx={{ textAlign: "center", fontWeight: 600 }}>
					Ask our team for more information.
				</Typography>
			</InfoModal>
			<Typography variant="h2">MyPerioPath®</Typography>
			<MyPerioPathTopBar />
			<Box height="35px" />
			<PerioPathChart data={data} />
		</RoundedContainer>
	);
};
