import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import Logo from "../assets/images/logo.svg";
import ProfilePhoto from "../assets/images/defaultProfileImage.png";
import { SideBarMenuItemProps } from "../componentProps/SideBarMenuItemProps";
import { Center } from "./Center";
import { ImagePlaceHolder } from "./ImagePlaceHolder";
import { KeyValueText } from "./KeyValueText";
import React, { useState } from "react";
import { ReactComponent as DashboardImage } from "../assets/images/dashboard.svg";
import { ReactComponent as CancerScreeningImage } from "../assets/images/cancer-screening.svg";
import { ReactComponent as CardioHealthImage } from "../assets/images/cardio-health.svg";
import { ReactComponent as MusculoskeletalImage } from "../assets/images/musculoskeletal.svg";
import { ReactComponent as BrainHealthImage } from "../assets/images/brain-health.svg";
import { ReactComponent as MetabolicHealthImage } from "../assets/images/metabolic-health.svg";
import { ReactComponent as MaternalHealthImage } from "../assets/images/maternal-health.svg";
import { SideBarMenuItem } from "./SideBarMenuItem";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { PatientInfo } from "../models/PatientInfo";

export const SideBar: React.FC<{ patientInfo?: PatientInfo }> = ({
	patientInfo,
}) => {
	const { isAuthenticated, logout, isLoading } = useAuth0();

	const [menuItems, setMenuItems] = useState<SideBarMenuItemProps[]>([
		{ title: "Dashboard", icon: <DashboardImage />, active: true },
		{
			title: "Cancer Screening",
			icon: <CancerScreeningImage />,
			active: false,
		},
		{
			title: "Cardiovascular Health",
			icon: <CardioHealthImage />,
			active: false,
		},
		{ title: "Musculoskeletal", icon: <MusculoskeletalImage />, active: false },
		{ title: "Brain Health", icon: <BrainHealthImage />, active: false },
		{
			title: "Metabolic Health",
			icon: <MetabolicHealthImage />,
			active: false,
		},
		{
			title: "Maternal Health",
			icon: <MaternalHealthImage />,
			active: false,
		},
	]);

	const patientKeyNamesAndValues: { keyName: string; value?: string }[] = [
		{ keyName: "First Name", value: patientInfo?.firstName },
		{ keyName: "Last Name", value: patientInfo?.lastName },
		{ keyName: "Patient ID", value: patientInfo?.patientId },
		{ keyName: "Age", value: patientInfo?.age.toString() },
		{ keyName: "Gender", value: patientInfo?.gender },
		{ keyName: "Height", value: patientInfo?.height.toString() },
		{ keyName: "Weight", value: patientInfo?.weight.toString() },
	];

	const handleMenuItemClick = (index: number) => {
		const menuItemsCopy = menuItems.map((menuItem: SideBarMenuItemProps) => {
			const menuItemCopy: SideBarMenuItemProps = {
				...menuItem,
				active: false,
			};
			return menuItemCopy;
		});

		menuItemsCopy[index].active = true;
		setMenuItems(menuItemsCopy);
	};

	const logoutWithRedirect = () =>
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});

	if (isLoading || !isAuthenticated) return <></>;

	return (
		<Box
			sx={{
				padding: "0 15px",
				background: "#fff",
				borderRadius: "15px",
				minWidth: "210px",
				maxWidth: { xs: "auto" },
			}}
		>
			<Center>
				<Box height="20px"></Box>
				<ImagePlaceHolder
					alt="Logo"
					src={Logo}
					widthLoading={100}
					heightLoading={100}
				/>
				<Box height={"10px"}></Box>
				<Typography fontSize={"0.8rem"} fontWeight={300} textAlign={"center"}>
					Next Appointment
				</Typography>
				<Box height="5px"></Box>
				<Typography
					lineHeight={1.1}
					fontSize="0.9rem"
					fontWeight={500}
					textAlign={"center"}
				>
					CCX - {dayjs(patientInfo?.nextAppointment).format("MM-DD-YYYY")}
					<br />
					{/* TODO: Add time zone if possible */}
					{dayjs(patientInfo?.nextAppointment).format("h:mm A")}
				</Typography>
				<Box height="30px"></Box>
				<Avatar
					sx={{ width: 88, height: 88 }}
					alt="Profile"
					src={ProfilePhoto}
				/>
			</Center>
			<Box height="30px"></Box>

			<Stack spacing={"15px"}>
				{patientKeyNamesAndValues.map(
					(patientKeyNameAndValue, index: number) => {
						return (
							<KeyValueText
								key={index}
								keyName={
									<Typography fontSize={"0.9rem"}>
										{patientKeyNameAndValue.keyName}:
									</Typography>
								}
								value={
									<Typography
										width={"70px"}
										textAlign={"left"}
										fontSize={"0.9rem"}
										fontWeight={600}
									>
										{patientKeyNameAndValue.value}
									</Typography>
								}
							/>
						);
					},
				)}
			</Stack>
			<Box height="40px"></Box>
			<Divider sx={{ borderColor: "#000" }} />
			<Box height="40px"></Box>
			{menuItems.map((menuItem: SideBarMenuItemProps, index: number) => {
				return (
					<Box key={index}>
						<SideBarMenuItem
							onClick={() => handleMenuItemClick(index)}
							sx={{ marginBottom: "20px" }}
							active={menuItem.active}
							icon={menuItem.icon}
							title={menuItem.title}
						/>
					</Box>
				);
			})}
			{/* TODO: Move logout to the ellipsis button */}
			{/* <Box>
				<Box
					onClick={logoutWithRedirect}
					sx={{ position: "relative", cursor: "pointer", marginBottom: "20px" }}
					display={"flex"}
				>
					<LogoutImage />
					<Box width="10px"></Box>
					<Typography fontWeight={500} color={"#000"}>
						Logout
					</Typography>
				</Box>
			</Box> */}
		</Box>
	);
};
