import { VitalSignsItem } from "./VitalSignsItem";
import { ReactComponent as BloodOxygenChartImage } from "../assets/images/blood-oxygen-chart.svg";

/**
 * The component that displays the blood oxygen of the patient
 *
 * @visibleName BloodOxygenVitalSignsItem
 */
export const BloodOxygenVitalSignsItem: React.FC = () => {
	return (
		<VitalSignsItem
			sx={{ position: "relative", overflow: "hidden" }}
			title={"Blood Oxygen"}
			subtitle={"97"}
		>
			<BloodOxygenChartImage
				style={{ position: "absolute", top: "50%", left: "-40%", right: 0 }}
			/>
		</VitalSignsItem>
	);
};
