import React from "react";
import {
	perioPathLimit,
	perioPathSeriesColors1,
	perioPathSeriesColors2,
	perioPathSeriesColors3,
	perioPathSeriesColors4,
} from "../constants";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { PerioPathDataPoint } from "../models/PerioPathDataPoint";
import { NumberHelper } from "../utils/NumberHelper";

export const PerioPathBarChart: React.FC<{
	data: PerioPathDataPoint;
	sx?: SxProps<Theme> | undefined;
}> = ({ data, sx }) => {
	const commonBarStyles: React.CSSProperties = {
		width: "13px",
		borderTopRightRadius: "3px",
		borderTopLeftRadius: "3px",
	};

	return (
		<Box sx={sx}>
			<Box
				sx={{
					width: "70px",
					height: "70px",
					display: "flex",
					alignItems: "flex-end",
				}}
			>
				<Box
					sx={{
						height: NumberHelper.getPercentage(data.td, perioPathLimit),
						backgroundColor: perioPathSeriesColors1,
						...commonBarStyles,
					}}
				/>
				<Box width={"5px"} />
				<Box
					sx={{
						height: NumberHelper.getPercentage(data.tf, perioPathLimit),
						backgroundColor: perioPathSeriesColors2,
						...commonBarStyles,
					}}
				/>
				<Box width={"5px"} />
				<Box
					sx={{
						height: NumberHelper.getPercentage(data.pg, perioPathLimit),
						backgroundColor: perioPathSeriesColors3,
						...commonBarStyles,
					}}
				/>
				<Box width={"5px"} />
				<Box
					sx={{
						height: NumberHelper.getPercentage(data.aa, perioPathLimit),
						backgroundColor: perioPathSeriesColors4,
						...commonBarStyles,
					}}
				/>
			</Box>
			<Box height="10px" />
			<Typography textAlign={"center"} fontWeight={400} fontSize={"0.9rem"}>
				{data.date}
			</Typography>
		</Box>
	);
};
