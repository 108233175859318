import { Box } from "@mui/material";
import React from "react";

/**
 * A component that mimics a key value text layout
 *
 * @visibleName KeyValueText
 */
export const KeyValueText: React.FC<{
	keyName: React.ReactNode;
	value: React.ReactNode;
}> = ({ keyName, value }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			{keyName}
			{value}
		</Box>
	);
};
