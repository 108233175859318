import { Box, Typography } from "@mui/material";
import {
	perioPathSeriesColors4,
	perioPathSeriesColors3,
	perioPathSeriesColors2,
	perioPathSeriesColors1,
} from "../constants";

export default function PerioPathLegend() {
	const perioPathLegendItem = (
		symbol: string,
		color: string,
		baseline: boolean = false,
	): React.ReactNode => {
		return (
			<Box
				sx={{
					border: "0.541875px solid #1E4D6F",
					borderRadius: "27.0938px",
					width: "54.73px",
					height: "24.93px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{baseline ? (
					<Box sx={{ display: "flex" }}>
						<Box
							sx={{
								width: "8px",
								height: "3px",
								borderRadius: "500px",
								backgroundColor: color,
							}}
						/>
						<Box width={"3px"} />
						<Box
							sx={{
								width: "8px",
								height: "3px",
								borderRadius: "500px",
								backgroundColor: color,
							}}
						/>
					</Box>
				) : (
					<Box
						sx={{
							height: "13px",
							width: "13px",
							backgroundColor: color,
							borderRadius: "50%",
						}}
					/>
				)}
				<Box width={baseline ? "5px" : "7px"} />
				<Typography sx={{ fontWeight: 500, fontSize: "0.8rem" }}>
					{symbol}
				</Typography>
			</Box>
		);
	};

	return (
		<Box>
			{/* Bacterias */}
			<Typography fontSize={"0.9rem"}>Bacterias</Typography>
			<Box height="20px" />
			<Box
				sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
			>
				{perioPathLegendItem("Aa", perioPathSeriesColors1)}
				<Box height="10px" />
				{perioPathLegendItem("Pg", perioPathSeriesColors2)}
				<Box height="10px" />
				{perioPathLegendItem("Tf", perioPathSeriesColors3)}
				<Box height="10px" />
				{perioPathLegendItem("Td", perioPathSeriesColors4)}
			</Box>
			<Box height="40px" />
			{/* Baselines */}
			<Typography fontSize={"0.9rem"}>Baselines</Typography>
			<Box height="20px" />
			<Box
				sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
			>
				{perioPathLegendItem("Aa", perioPathSeriesColors4, true)}
				<Box height="10px" />
				{perioPathLegendItem("Pg", perioPathSeriesColors3, true)}
				<Box height="10px" />
				{perioPathLegendItem("Tf", perioPathSeriesColors2, true)}
				<Box height="10px" />
				{perioPathLegendItem("Td", perioPathSeriesColors1, true)}
			</Box>
		</Box>
	);
}
