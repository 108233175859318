import { Box, SxProps, Theme, Typography } from "@mui/material";
import { RoundedContainer } from "./RoundedContainer";
import {
	ammp8SeriesColors1,
	ammp8SeriesColors2,
	ammp8SeriesColors3,
} from "../constants";
import { StringReplacer } from "../utils/StringReplacer";
import { NumberHelper } from "../utils/NumberHelper";
import { Ammp8GraphYAxis } from "./Ammp8GraphYAxis";
import { Ammp8BarValue } from "../models/Ammp8BarValue";
import { Fragment, useState } from "react";
import { Center } from "./Center";
import { InfoModal } from "./InfoModal";
import { ReactComponent as ToothImage } from "../assets/images/tooth.svg";

/**
 * The component that displays the AMMP-8 results in a graph
 *
 * @visibleName Ammp8GraphGridMarkerText
 */
export const Ammp8Graph: React.FC<{ ammpData: Ammp8BarValue[] }> = ({
	ammpData,
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	return (
		<RoundedContainer
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
			onMoreButtonClicked={() => setShowModal(!showModal)}
		>
			<InfoModal show={showModal}>
				<Typography
					variant="h3"
					sx={{ textAlign: "center", fontWeight: 700, fontSize: "1.1rem" }}
				>
					Did You Know?
				</Typography>
				<Box height="5px" />
				<Typography
					sx={{ textAlign: "center", lineHeight: "16px", fontWeight: 500 }}
				>
					We offer biomarker screenings.
				</Typography>
				<Box height="25px" />
				<Center>
					<ToothImage />
				</Center>
				<Box height="30px" />
				<Typography sx={{ textAlign: "center", lineHeight: "16px" }}>
					Screening for biomarkers may prevent problems before they arise,
					allowing us to create personalized treatment options for you.
				</Typography>
				<Box height="15px" />
				<Typography sx={{ textAlign: "center", fontWeight: 600 }}>
					Ask our team for more information.
				</Typography>
			</InfoModal>
			<Typography variant="h2">aMMP-8</Typography>
			<Box height="30px" />
			<Ammp8GraphYAxis />
			<Box
				sx={{
					position: "absolute",
					height: "76.7%",
					width: "72.6%",
					bottom: "12.6%",
					left: "20%",
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "space-around",
				}}
			>
				{/* Bars */}
				{ammpData.map((data: Ammp8BarValue, index: number): React.ReactNode => {
					return (
						<Fragment key={index}>
							{/* {index == 0 && <Box width="13px" />} */}
							<Ammp8GraphBar sx={{}} value={data.value} />
							{/* <Box width="30px" /> */}
						</Fragment>
					);
				})}

				{/* Bars */}
			</Box>
			<Box sx={{ height: "30px" }} />
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-around",
					width: "85.6%",
					marginLeft: "41px",
				}}
			>
				{ammpData.map((data: Ammp8BarValue, index: number): React.ReactNode => {
					return (
						<Typography key={index} fontSize={"0.9rem"}>
							{data.date}
						</Typography>
					);
				})}
			</Box>
		</RoundedContainer>
	);
};

/**
 * The text next to the grid marker for the Ammp8Graph
 *
 * @visibleName Ammp8GraphGridMarkerText
 */
const Ammp8GraphBar: React.FC<{
	value: number;
	sx?: SxProps<Theme> | undefined;
}> = ({ sx, value }) => {
	/// Gets the color for a given ammp-8 value
	const _getBarColor = (value: number): string => {
		if (value >= 0 && value <= 30) {
			return ammp8SeriesColors3;
		} else if (value > 30 && value <= 40) {
			return ammp8SeriesColors2;
		} else {
			return ammp8SeriesColors1;
		}
	};

	const barColor = _getBarColor(value);
	const baselineValue = 10;

	return (
		<Box
			sx={{
				position: "relative",
				zIndex: 3,
				height: NumberHelper.getPercentage(value - baselineValue, 40),
				...sx,
			}}
		>
			<Typography
				sx={{
					position: "absolute",
					left: "-18px",
					fontSize: "0.7rem",
					color: barColor,
					zIndex: 999,
					fontWeight: "bold",
				}}
			>
				{value}
			</Typography>
			<Box
				sx={{
					width: "12.5px",
					height: "100%",
					borderRadius: "20px",
					backgroundColor: barColor,
					zIndex: 99999,
					boxShadow: `-4px 0px 5px ${new StringReplacer().replaceLastOccurenceOf(
						barColor,
						"1",
						"0.5",
					)}`,
				}}
			/>
		</Box>
	);
};
