import React from "react";
import { ReactComponent as BloodPressureIndicatorImage } from "../assets/images/blood-pressure-indicator.svg";
import { Box } from "@mui/material";
import { VitalSignsItem } from "./VitalSignsItem";
import { isSafari } from "react-device-detect";

/**
 * The component that displays the blood pressure of the patient
 *
 * @visibleName BloodPressureVitalSignsItem
 */
export const BloodPressureVitalSignsItem: React.FC = () => {
	const bloodPressureIndicatorStyles: React.CSSProperties = {
		position: "absolute",
		height: "27px",
		// Safari does not support this drop shadow value
		filter: !isSafari
			? "drop-shadow(0px 100px 167px rgba(255, 88, 20, 0.42)) drop-shadow(0px 41.7776px 69.7686px rgba(255, 88, 20, 0.301919)) drop-shadow(0px 22.3363px 37.3016px rgba(255, 88, 20, 0.250365)) drop-shadow(0px 12.5216px 20.911px rgba(255, 88, 20, 0.21)) drop-shadow(0px 6.6501px 11.1057px rgba(255, 88, 20, 0.169635)) drop-shadow(0px 2.76726px 4.62132px rgba(255, 88, 20, 0.118081))"
			: "none",
	};
	return (
		<VitalSignsItem title={"Blood Pressure"} subtitle={"165/95"}>
			<Box sx={{ position: "relative" }}>
				<BloodPressureIndicatorImage
					style={{
						top: "36px",
						left: 0,
						right: "95.65%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "42px",
						left: "10.56%",
						right: "85.09%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "52px",
						left: "21.12%",
						right: "74.53%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "42px",
						left: "31.68%",
						right: "63.98%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "28px",
						left: "42.24%",
						right: "53.42%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "12px",
						left: "52.8%",
						right: "42.86%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "28px",
						left: "63.35%",
						right: "32.3%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "42px",
						left: "73.91%",
						right: "21.74%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "52px",
						left: "84.47%",
						right: "11.18%",
						...bloodPressureIndicatorStyles,
					}}
				/>
				<BloodPressureIndicatorImage
					style={{
						top: "42px",
						left: "95.65%",
						right: "0",
						...bloodPressureIndicatorStyles,
					}}
				/>
			</Box>
		</VitalSignsItem>
	);
};
