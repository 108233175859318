import { useState } from "react";
import { BloodOxygenVitalSignsItem } from "./BloodOxygenVitalSignsItem";
import { BloodPressureVitalSignsItem } from "./BloodPressureVitalSignsItem";
import { HeartRateVitalSignsItem } from "./HeartRateVitalSignsItem";
import { RoundedContainer } from "./RoundedContainer";
import { Box, Grid, Typography } from "@mui/material";
import { InfoModal } from "./InfoModal";
import HeartRateImage from "../assets/images/heart-rate.png";
import { Center } from "./Center";

/**
 * The vitals signs of the patient
 *
 * @visibleName VitalSigns
 */
export const VitalSigns: React.FC = () => {
	const [showModal, setShowModal] = useState<boolean>(false);

	return (
		<RoundedContainer
			sx={{ height: "100%", position: "relative" }}
			onMoreButtonClicked={() => setShowModal(!showModal)}
		>
			<InfoModal show={showModal}>
				<Typography
					variant="h3"
					sx={{ textAlign: "center", fontWeight: 700, fontSize: "1.1rem" }}
				>
					Did You Know?
				</Typography>
				<Box height="5px" />
				<Typography
					sx={{ textAlign: "center", lineHeight: "16px", fontWeight: 500 }}
				>
					You can be screened for blood pressure, blood oxygen, and heart rate.
				</Typography>
				<Box height="15px" />
				<Center>
					<img src={HeartRateImage} alt="Heart Rate" />
				</Center>
				<Box height="15px" />
				<Typography sx={{ textAlign: "center", lineHeight: "16px" }}>
					Regular monitoring of vital signs can help identify potential health
					problems.
				</Typography>
				<Box height="10px" />
				<Typography sx={{ textAlign: "center", fontWeight: 600 }}>
					Ask our team for more information.
				</Typography>
			</InfoModal>
			<Box height="8px" />
			<Box display="flex" alignItems={"center"}>
				<Typography variant="h2">Vital Signs</Typography>
				<Box width="20px" />
				<Typography fontWeight={500}>18 mins ago</Typography>
			</Box>
			<Box height="25px" />
			<Grid spacing={"20px"} container>
				<Grid item xs={12} md={4}>
					<BloodPressureVitalSignsItem />
				</Grid>
				<Grid item xs={12} md={4}>
					<HeartRateVitalSignsItem />
				</Grid>
				<Grid item xs={12} md={4}>
					<BloodOxygenVitalSignsItem />
				</Grid>
			</Grid>
		</RoundedContainer>
	);
};
