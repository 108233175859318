import { Box, Container } from "@mui/material";
import React from "react";
import { PatientInfo } from "../models/PatientInfo";
import { SideBar } from "./SideBar";

/**
 * The Layout of each page. Sidebar goes to the left and content
 * to the right
 *
 * @visibleName Layout
 */
export const Layout: React.FC<{
	children?: React.ReactNode;
	patientInfo?: PatientInfo;
}> = ({ children, patientInfo }) => {
	return (
		<Box>
			<Container maxWidth={false} sx={{ maxWidth: "1880px" }}>
				<Box height={"30px"}></Box>
				<Box display={{ xs: "block", md: "flex", alignItems: "stretch" }}>
					<SideBar patientInfo={patientInfo} />
					<Box
						sx={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
					>
						{children}
					</Box>
				</Box>
				<Box height={"30px"}></Box>
			</Container>
		</Box>
	);
};
