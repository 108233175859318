import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import { App } from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";


const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),    
  },
};


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);


root.render(
	<Auth0Provider {...providerConfig}>	
		<App />
	</Auth0Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
