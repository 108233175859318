import { Box, Fade } from "@mui/material";
import { InfoModalProps } from "../componentProps/InfoModalProps";

export const InfoModal: React.FC<InfoModalProps> = ({ children, sx, show }) => {
	return (
		<Fade in={show}>
			<Box
				sx={{
					boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
					width: "300px",
					height: "280px",
					borderRadius: "8px",
					padding: "15px 25px",
					backgroundColor: "#ffffff",
					transition: "all 0.2s ease-out",
					position: "absolute",
					zIndex: 99,
					top: 60,
					right: 30,
					...sx,
				}}
			>
				{children}
			</Box>
		</Fade>
	);
};
