import { Box, Divider, SxProps, Theme } from "@mui/material";
import { PerioPathDataPoint } from "../models/PerioPathDataPoint";
import { PerioPathBarChart } from "./PerioPathBarChart";

export const PerioPathChartVerticalGridLinesAndCharts: React.FC<{
	data: PerioPathDataPoint[];
	sx?: SxProps<Theme> | undefined;
}> = ({ data, sx }) => {
	const innerGridLinesLength = data.length - 1;

	const getFirstChartLeftPosition = (dataLength: number): string => {
		switch (dataLength) {
			case 2:
				return "63px";
			case 3:
				return "63px";
			default:
				return "-35px";
		}
	};

	return (
		<Box
			sx={{
				height: "100%",
				...sx,
			}}
		>
			{data
				.slice(0, innerGridLinesLength)
				.map((datal: PerioPathDataPoint, index: number) => {
					const gridLineleftPosition = `calc((100% / ${innerGridLinesLength} * ${index}) + (100% / ${innerGridLinesLength} / 2) )`;
					const chartLeftPosition = `calc((100% / ${innerGridLinesLength} * ${index}) + (100% / ${innerGridLinesLength}) - 35px )`;
					return (
						<Box key={index}>
							<Divider
								sx={{
									borderColor: "#DCDCDC",
									position: "absolute",
									left: gridLineleftPosition,
								}}
								orientation="vertical"
							/>
							{/* Show the first column chart */}
							{index === 0 && (
								<PerioPathBarChart
									sx={{
										position: "absolute",
										left: getFirstChartLeftPosition(data.length),
										bottom: "10px",
									}}
									data={datal}
								/>
							)}
							<PerioPathBarChart
								sx={{
									position: "absolute",
									left: chartLeftPosition,
									bottom: "10px",
								}}
								data={data[index + 1]}
							/>
						</Box>
					);
				})}
		</Box>
	);
};
