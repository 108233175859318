import { Box, Grid, Typography } from "@mui/material";

import { Layout } from "../components/Layout";

import {
	sampleAmmp8BarData,
	samplePatientOralDNA1,
	samplePatientVitals,
	samplePerioPathData,
} from "../constants";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useLocation } from "react-router-dom";
import { PatientVitals } from "../models/PatientVitals";
import { Loading } from "../components/Loading";
import { PatientOralDNA } from "../models/PatientOralDNA";
import { PatientInfo } from "../models/PatientInfo";

import APIService from "../services/APIService";
import { VitalSigns } from "../components/VitalSigns";
import { Ammp8Graph } from "../components/Ammp8Graph";
import { HbA1c } from "../components/HbA1c";
import { MyPerioPath } from "../components/MyPerioPath";

export const Home = () => {
	const { getPatientOralDNA, getPatientVitals } = APIService;

	const [patient, setPatient] = useState<PatientVitals | undefined>();
	const [patientOralDNA, setPatientOralDNA] = useState<
		PatientOralDNA | undefined
	>();
	const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>();

	const [apiLoading, setApiLoading] = useState(false);

	const { isLoading, getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const navigationState = location.state;

	useEffect(() => {
		const loadPatientVitalsWithToken = async (): Promise<
			PatientVitals | undefined
		> => {
			var token = await getAccessTokenSilently();
			const response = await getPatientVitals(
				token,
				navigationState.patient.serviceAreaId,
				navigationState.patient.mrn,
			);
			return response;
		};

		setApiLoading(true);
		loadPatientVitalsWithToken()
			.then((patientVitals: PatientVitals | undefined) => {
				setPatient(patientVitals);
				setApiLoading(false);
				setPatientOralDNA(samplePatientOralDNA1);
			})
			.catch((e) => {
				console.error(e);
				setPatient(samplePatientVitals);
				setApiLoading(false);
			});

		setPatientInfo(navigationState.patient);

		// // TODO: Pull in patient data from API, using mrn
		// setTimeout(() => {

		// 	setApiLoading(false);

		// 	setPatient(samplePatientVitals);
		// 	setPatientOralDNA(samplePatientOralDNA);
		// 	setPatientInfo(samplePatientInfos[0]);

		// }, 2000);
	}, [getAccessTokenSilently, getPatientVitals, location, navigationState]);

	return apiLoading ? (
		<Loading />
	) : (
		<>
			{!isLoading && (
				<Layout patientInfo={patientInfo}>
					<Box sx={{ margin: "0 auto" }}>
						<Typography variant="h1" fontSize={"1.8rem"} fontWeight={300}>
							Patient Dashboard - Mouth-Body Connection®
						</Typography>
						<Box height="18px"></Box>
						{/* Vital Signs and HbA1c graph */}
						<Grid container columnSpacing="15px">
							<Grid item xs={12} md={5}>
								<VitalSigns />
							</Grid>
							<Grid item xs={12} md={7}>
								<HbA1c hbA1cValue={8.2} />
							</Grid>
						</Grid>

						<Box height="40px" />

						{/* AMMP-8 and MyPerioPath graphs */}
						<Grid container columnSpacing="20px">
							<Grid item xs={12} md={2.5}>
								<Ammp8Graph ammpData={sampleAmmp8BarData} />
							</Grid>
							<Grid item xs={12} md={9.5}>
								<MyPerioPath data={samplePerioPathData} />
							</Grid>
						</Grid>
					</Box>
				</Layout>
			)}
		</>
	);
};
