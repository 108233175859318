import { createTheme, Theme } from "@mui/material/styles";
/**
 * The themeing of the web application
 */
export const sherpaTheme: Theme = createTheme({
	typography: {
		fontFamily: ["'Poppins'", "sans-serif"].join(","),
		fontWeightLight: 100,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		allVariants: {
			color: "#000",
		},
		h1: {
			fontSize: "2.5rem",
			fontWeight: "100",
		},
		h2: {
			fontSize: "1.3rem",
			fontWeight: "400",
		},
		h3: {
			fontSize: "0.9rem",
			fontWeight: "600",
		},
		h4: {
			fontSize: "0.9rem",
			fontWeight: "bold",
		},
		body1: {
			fontWeight: "400",
			lineHeight: "1.4",
		},
	},
	palette: {
		primary: {
			main: "#000000",
		},
		secondary: {
			main: "#dd9933",
		},
		divider: "#939696",
		background: {
			default: "#DADBE8",
		},
	},
	components: {
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: "15px",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltipArrow: {
					"& .MuiTooltip-arrow": {
						color: "#C1C1C1",
					},
				},
				tooltipPlacementTop: {
					height: "75px",
					width: "250px",
					color: "black",
					backgroundColor: "#C1C1C1",
					boxShadow: "none",
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: "outlined" },
					style: {
						color: "#000000",
						height: "24.93px",
						boxShadow: "none",
						border: "0.541875px solid #1E4D6F",
						background: "transparent",
						textTransform: "none",
						borderRadius: "27.0938px",
						fontWeight: 500,
						fontSize: "0.7rem",
						"&:focus": {
							border: "0.541875px solid #1E4D6F",
							color: "#000000",
							boxShadow: "none",
							background: "transparent",
						},
						"&:hover": {
							border: "0.541875px solid #1E4D6F",
							color: "#000000",
							boxShadow: "none",
							background: "transparent",
						},
					},
				},
			],
		},
	},
});
