import { Box } from "@mui/material";
import { ReactComponent as TransparentHexImage } from "../assets/images/transparent-hex.svg";
import { ReactComponent as HexColoredImage } from "../assets/images/colored-hex.svg";

interface HexColumnProps {
	/** The number of hexagons in the column or row */
	number?: number | undefined;
	/** Color of each hexagon in the column or row */
	color?: string | undefined;
}

export const HexColumn: React.FC<HexColumnProps> = ({ number = 9, color }) => {
	const getLeftPositionOfHex = (index: number): string => {
		const isOdd = index % 2;
		if (isOdd) {
			return "9px";
		}

		return "0";
	};

	const getTopPositionOfHex = (index: number): string => {
		return `${index * 17}px`;
	};

	return (
		<Box
			sx={{
				display: "flex",
				position: "relative",
				flexDirection: "column",
				height: "150px",
				width: "18px",
			}}
		>
			{Array.from(Array(number), (_, index: number) => {
				return color ? (
					<HexColoredImage
						style={{
							fill: color,
							position: "absolute",
							left: getLeftPositionOfHex(index),
							top: getTopPositionOfHex(index),
						}}
						key={index}
					/>
				) : (
					<TransparentHexImage
						style={{
							position: "absolute",
							left: getLeftPositionOfHex(index),
							top: getTopPositionOfHex(index),
						}}
						key={index}
					/>
				);
			})}
		</Box>
	);
};
