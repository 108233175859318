import { Typography, Box, SxProps, Theme } from "@mui/material";
import { RoundedContainer } from "./RoundedContainer";

interface VitalSignsItemProps {
	/** The title of the vital signs item. */
	title: string;
	/** The subtitle of the vital signs item. It goes below the title. */
	subtitle: string;
	/** The content to display in the vital signs item. It goes below the subtitle. */
	children?: React.ReactNode | undefined;
	sx?: SxProps<Theme> | undefined;
}

/**
 * A child of the VitalSigns container
 *
 * @visibleName VitalSignsItem
 */
export const VitalSignsItem: React.FC<VitalSignsItemProps> = ({
	title,
	subtitle,
	children,
	sx,
}) => {
	return (
		<RoundedContainer
			sx={{
				borderRadius: "10px",
				backgroundColor: "#F2F3F7",
				padding: "10px 20px",
				height: "203px",
				width: "185px",
				...sx,
			}}
		>
			<Typography variant="h3">{title}</Typography>
			<Box height="3px" />
			<Typography fontWeight={500} variant="h3" fontSize="1.4rem">
				{subtitle}
			</Typography>
			<Box height="15px" />
			{children}
		</RoundedContainer>
	);
};
