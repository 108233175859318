// PerioPathDataHelper
/** A class to help us perform common numeric operations */
export class NumberHelper {
	/**
	 * Gets the percentage value by dividing the value by the total
	 * @param  {number}  value              The number we will divide by the total
	 * @param  {number}  total              The total the value will be divided by
	 * @param  {boolean} addPercentageSign  Whether or not a percentage sign should be added to the result
	 * @return {string}                     The percent result
	 */
	static getPercentage(
		value: number,
		total: number,
		addPercentageSign: boolean = true,
	): string {
		const result = Math.round((value / total) * 100);
		return `${result}${addPercentageSign ? "%" : ""}`;
	}

	/**
	 * Returns a random number between min (inclusive) and max (exclusive)
	 * @param  {number}  min The min number (inclusive) in the range
	 * @param  {number}  max The max number (exclusive) in the range
	 * @return {number}  The random number within the range
	 */
	static getRandomArbitrary(min: number, max: number): number {
		return NumberHelper.roundNumberToTwoPlaces(
			Math.random() * (max - min) + min,
		);
	}

	/**
	 * Rounds a number to decimal places
	 * @param  {number} num The number to round
	 * @return {number}     The rounded number
	 */
	static roundNumberToTwoPlaces(num: number): number {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	}
}
