import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { CurvedDottedLine } from "./CurvedDottedLines";
import {
	ammp8SeriesColors1,
	ammp8SeriesColors2,
	ammp8SeriesColors3,
} from "../constants";

export const Ammp8GraphYAxis = () => {
	const curvedDottedLineLength = 10;
	const curvedDottedLineCommonStyles: React.CSSProperties = {
		marginTop: "10px",
		marginLeft: "8px",
	};

	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			{/* 50 ng/ml */}
			<Box sx={{ display: "flex" }}>
				<Ammp8GraphGridMarkerText number={50} />
				<CurvedDottedLine
					length={curvedDottedLineLength}
					color={ammp8SeriesColors1}
					sx={curvedDottedLineCommonStyles}
				/>
			</Box>
			{/* 40 ng/ml */}
			<Box sx={{ display: "flex" }}>
				<Ammp8GraphGridMarkerText number={40} />
				<CurvedDottedLine
					length={curvedDottedLineLength}
					color={ammp8SeriesColors2}
					sx={curvedDottedLineCommonStyles}
				/>
			</Box>
			{/* 30 ng/ml */}
			<Box sx={{ display: "flex" }}>
				<Ammp8GraphGridMarkerText number={30} />
				<CurvedDottedLine
					length={curvedDottedLineLength}
					color={ammp8SeriesColors3}
					sx={curvedDottedLineCommonStyles}
				/>
			</Box>
			{/* 20 ng/ml */}
			<Box sx={{ display: "flex" }}>
				<Ammp8GraphGridMarkerText number={20} />
				<CurvedDottedLine
					length={curvedDottedLineLength}
					color={ammp8SeriesColors3}
					sx={curvedDottedLineCommonStyles}
				/>
			</Box>
			{/* 10 ng/ml */}
			<Box sx={{ display: "flex" }}>
				<Ammp8GraphGridMarkerText number={10} />
				<CurvedDottedLine
					length={curvedDottedLineLength}
					color={ammp8SeriesColors3}
					sx={curvedDottedLineCommonStyles}
				/>
			</Box>
		</Box>
	);
};

/**
 * The text next to the grid marker for the Ammp8Graph
 *
 * @visibleName Ammp8GraphGridMarkerText
 */
const Ammp8GraphGridMarkerText: React.FC<{
	number: number;
	sx?: SxProps<Theme> | undefined;
}> = ({ number, sx }) => {
	return (
		<Box sx={sx}>
			<Typography fontSize={"1rem"} variant="h3">
				{number}
			</Typography>
			<Typography fontSize={"0.7rem"}>ng/ml</Typography>
		</Box>
	);
};
