import { ButtonBase, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { PatientInfo } from "../models/PatientInfo";

export const PatientSelectionBox: React.FC<{
	patient: PatientInfo;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ patient, onClick }) => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "12%",
				backgroundColor: "#77BAF9",
				boxSizing: "border-box",
			}}
		>
			<ButtonBase
				onClick={onClick}
				sx={{
					width: "100%",
					height: "100%",
					display: "block",
					padding: "10px",
				}}
			>
				<Typography
					sx={{
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						display: "block",
						overflow: "hidden",
						fontWeight: "bold",
						fontSize: "0.7rem",
					}}
				>{`${patient.firstName} ${patient.lastName}`}</Typography>
				<Typography
					sx={{
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						display: "block",
						overflow: "hidden",
						fontSize: "0.7rem",
					}}
				>{`${patient.gender}; ${patient.age} yrs`}</Typography>
			</ButtonBase>
		</Box>
	);
};
