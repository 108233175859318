import { Box, SxProps, Theme } from "@mui/material";
import { CurvedDottedLine } from "./CurvedDottedLines";

export const PerioPathChartHorizonalLimitLines: React.FC<{
	sx?: SxProps<Theme> | undefined;
}> = ({ sx }) => {
	const commonDottedLineStyles: React.CSSProperties = {
		position: "absolute",
		left: "-2.4%",
	};
	const curvedDottedLineLength = 53;

	return (
		<Box
			sx={{
				height: "100%",
				overflow: "hidden",
				...sx,
			}}
		>
			<Box
				sx={{
					...commonDottedLineStyles,
					top: "28%",
				}}
			>
				<CurvedDottedLine length={curvedDottedLineLength} color="#0BDB2C" />
			</Box>
			<Box
				sx={{
					...commonDottedLineStyles,
					top: "40%",
				}}
			>
				<CurvedDottedLine length={curvedDottedLineLength} color="#8676FF" />
			</Box>
			<Box
				sx={{
					...commonDottedLineStyles,
					top: "42%",
				}}
			>
				<CurvedDottedLine length={curvedDottedLineLength} color="#FFB443" />
			</Box>
			<Box
				sx={{
					...commonDottedLineStyles,
					top: "48%",
				}}
			>
				<CurvedDottedLine length={curvedDottedLineLength} color="#DD392F" />
			</Box>
		</Box>
	);
};
