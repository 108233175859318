import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./Loading";

const ProtectedRoute = ({children}: any) => {

  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  console.log('authenticated!')
  console.log(user)

  return children;
};


export default ProtectedRoute;

