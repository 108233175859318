import { Box, Divider, Typography } from "@mui/material";
import { ReactComponent as HexColoredImage } from "../assets/images/colored-hex.svg";
import { Fragment } from "react";
import {
	greenHexColor,
	maxHbA1cValue,
	orangeHexColor,
	redHexColor,
} from "../constants";
import { NumberHelper } from "../utils/NumberHelper";

export const HbA1cAxis: React.FC<{ hbA1cValue: number }> = ({ hbA1cValue }) => {
	const markerSpacing = "42px";
	const totalMarkers = 26; // not counting the first marker because it is zero
	const showHexOffset = 0.2;

	// Converts the index of a hex marker to a HbA1c value
	const indexToHbA1cValue = (index: number): number => {
		return (index / totalMarkers) * maxHbA1cValue;
	};

	// Gets the color for the title that displays
	// the hba1c value
	const getTextColor = (hbA1cValue: number): string => {
		if (hbA1cValue >= 0 && hbA1cValue <= 4.6) {
			return greenHexColor;
		} else if (hbA1cValue > 4.6 && hbA1cValue <= 9.8) {
			return orangeHexColor;
		} else {
			return redHexColor;
		}
	};

	// 27 markers
	return (
		<Box sx={{ display: "flex", position: "relative" }}>
			<Typography
				sx={{
					position: "absolute",
					left: `calc(${NumberHelper.getPercentage(
						hbA1cValue,
						maxHbA1cValue,
					)} - 25.19px)`,
					fontSize: "16px",
					fontWeight: 700,
					color: getTextColor(hbA1cValue),
				}}
				variant="h2"
			>
				{hbA1cValue}
			</Typography>
			{/* Generate green markers */}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(0)}
				color={greenHexColor}
				label="0%"
			/>
			<Box width={markerSpacing} />
			{Array.from(Array(7), (_, index: number) => {
				const indexOffset = 1;
				const indexHbA1cValue = indexToHbA1cValue(index + indexOffset);
				return (
					<Fragment key={index}>
						<HbA1cAxisMarker
							showHex={indexHbA1cValue <= hbA1cValue}
							color={greenHexColor}
						/>
						<Box width={markerSpacing} />
					</Fragment>
				);
			})}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(8)}
				color={greenHexColor}
				label={((maxHbA1cValue / totalMarkers) * 8).toPrecision(2)}
			/>
			<Box width={markerSpacing} />
			{/* Generate orange markers */}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(9)}
				color={orangeHexColor}
				label={((maxHbA1cValue / totalMarkers) * 9).toPrecision(2)}
			/>
			<Box width={markerSpacing} />
			{Array.from(Array(7), (_, index: number) => {
				const incrementIndexBy = 10;
				const indexHbA1cValue = indexToHbA1cValue(index + incrementIndexBy);
				return (
					<Fragment key={index}>
						<HbA1cAxisMarker
							showHex={
								// TODO: Apply this condition to all hex markers and
								// test it out to see if it gives the desired look
								// in the UI
								indexHbA1cValue < hbA1cValue - showHexOffset &&
								indexHbA1cValue < hbA1cValue + showHexOffset
							}
							color={orangeHexColor}
						/>
						<Box width={markerSpacing} />
					</Fragment>
				);
			})}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(17)}
				color={orangeHexColor}
				label={((maxHbA1cValue / totalMarkers) * 17).toPrecision(2)}
			/>
			<Box width={markerSpacing} />
			{/* Generate red markers */}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(18)}
				color={redHexColor}
				label={((maxHbA1cValue / totalMarkers) * 18).toFixed(1)}
			/>
			<Box width={markerSpacing} />
			{Array.from(Array(7), (_, index: number) => {
				const incrementIndexBy = 19;
				const indexHbA1cValue = indexToHbA1cValue(index + incrementIndexBy);
				return (
					<Fragment key={index}>
						<HbA1cAxisMarker
							showHex={indexHbA1cValue <= hbA1cValue}
							color={redHexColor}
						/>
						<Box width={markerSpacing} />
					</Fragment>
				);
			})}
			<HbA1cAxisMarker
				showHex={hbA1cValue !== indexToHbA1cValue(26)}
				color={redHexColor}
				label={maxHbA1cValue.toString()}
			/>
		</Box>
	);
};

const HbA1cAxisMarker: React.FC<{
	label?: string | undefined;
	color: string;
	showHex?: boolean | undefined;
}> = ({ label, color, showHex = true }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{showHex ? (
				<HexColoredImage style={{ fill: color }} />
			) : (
				<Box width="16px" height="20px" />
			)}
			<Box sx={{ height: "3px" }} />
			<Divider sx={{ height: "6.46px", color: color }} orientation="vertical" />
			<Box sx={{ height: "5px" }} />
			{label && (
				<Typography sx={{ fontWeight: "300", fontSize: "0.8rem" }} variant="h3">
					{label}
				</Typography>
			)}
		</Box>
	);
};
