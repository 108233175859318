import { Box, ButtonBase, SxProps, Theme } from "@mui/material";
import { ReactComponent as MoreVertIcon } from "../assets/images/more-vert.svg";
import React from "react";

type RoundedContainerProps = {
	children?: React.ReactNode | undefined;
	sx?: SxProps<Theme> | undefined;
	/** The handler for when the more button is clicked */
	onMoreButtonClicked?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

/**
 * A container with rounded edges that is used to enclose its children
 *
 * @visibleName RoundedContainer
 */
export const RoundedContainer: React.FC<RoundedContainerProps> = ({
	children,
	sx,
	onMoreButtonClicked,
}) => {
	return (
		<Box
			sx={{
				position: "relative",
				borderRadius: "16px",
				backgroundColor: "#fff",
				padding: "15px 25px 25px 25px",
				boxSizing: "border-box",
				...sx,
			}}
		>
			{onMoreButtonClicked && (
				<ButtonBase
					onClick={onMoreButtonClicked}
					sx={{
						position: "absolute",
						right: "30px",
						top: "17px",
						borderRadius: "4px",
					}}
				>
					<Box
						sx={{
							border: "1px solid #000",
							borderRadius: "4px",
							width: "30px",
							height: "30px",
							fontSize: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<MoreVertIcon fontSize="inherit" />
					</Box>
				</ButtonBase>
			)}
			{children}
		</Box>
	);
};
