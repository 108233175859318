import { getConfig } from "../config";
import { PatientInfo } from "../models/PatientInfo";
import { PatientOralDNA } from "../models/PatientOralDNA";
import { PatientVitals } from "../models/PatientVitals";

const { apiOrigin } = getConfig();

const getPatients = async (
	accessToken: string,
	serviceAreaId: string,
): Promise<PatientInfo[]> => {
	const response = await fetch(`${apiOrigin}/PatientInfo/${serviceAreaId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	return await response.json();
};

const getPatientVitals = async (
	accessToken: string,
	serviceAreaId: string,
	mrn: string,
): Promise<PatientVitals | undefined> => {
	const response = await fetch(
		`${apiOrigin}/PatientVitals/${serviceAreaId}/${mrn}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		},
	);

	return await response.json();
};

const getPatientOralDNA = async (
	accessToken: string,
	serviceAreaId: string,
	mrn: string,
): Promise<PatientOralDNA | undefined> => {
	return undefined;
};

const APIService = {
	getPatients,
	getPatientVitals,
	getPatientOralDNA,
};

export default APIService;
