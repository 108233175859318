import { useNavigate, useLocation } from "react-router-dom";
import PatientDetailImg from "../assets/images/patientDetail-background.png";
import ProfileImg from "../assets/images/defaultProfileImage.png";
import SherpaIcon from "../assets/images/sherpa-icon.jpg";
import { PatientInfo } from "../models/PatientInfo";

export const PatientDetail: React.FC = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const { firstName, lastName, gender, age, mrn, doB }: PatientInfo =
		location.state.patient;
	const ptFullName = firstName + " " + lastName;
	const ptInfo = gender + ", " + age + " y.o., " + doB;
	const ptMRN = "MRN: " + mrn;

	console.log(location.state.patient);

	const onClickSherpa = () => {
		navigate("/sherpa-dashboard", {
			state: {
				patient: location.state.patient,
			},
		});
	};

	return (
		<div style={{ position: "relative" }}>
			<img
				src={PatientDetailImg}
				alt="Sherpa Dashboard"
				style={{ width: "100%", height: "auto" }}
			/>

			<div
				style={{
					position: "absolute",
					top: "8%",
					left: "8%",
					width: "4.5%",
					height: "9%",
					borderRadius: "80%",
					overflow: "hidden",
				}}
			>
				<img
					src={ProfileImg}
					alt="Patient Profile"
					style={{
						position: "absolute",
						top: "-2%",
						left: "-6%",
						width: "94px",
						height: "88px",
					}}
				/>
			</div>

			<div
				style={{
					position: "absolute",
					top: "17%",
					left: "6%",
					backgroundColor: "#dff3ef",
					color: "#01513a",
					width: "9%",
					height: "7%",
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				{ptFullName}
			</div>

			<div
				style={{
					position: "absolute",
					top: "19%",
					left: "6%",
					backgroundColor: "#dff3ef",
					color: "#666f7c",
					width: "9%",
					height: "3%",
					fontWeight: "normal",
					textAlign: "center",
					fontSize: "12.5px",
				}}
			>
				{ptInfo}
				<br />
				{ptMRN}
			</div>

			<button
				onClick={onClickSherpa}
				style={{
					position: "absolute",
					top: "8%",
					left: "49%",
					height: "37px",
					width: "98px",
				}}
			>
				<img
					src={SherpaIcon}
					alt="Sherpa Icon"
					style={{
						position: "absolute",
						top: "-4.1%",
						left: "-2%",
						height: "37px",
						width: "98px",
						borderLeft: "1px solid #a1d9cb",
						borderRight: "1px solid #a1d9cb",
						cursor: "pointer",
						filter: "brightness(74%) contrast(200%) hue-rotate(326deg)",
					}}
				/>
			</button>
		</div>
	);
};
