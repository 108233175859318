import { Box, Typography } from "@mui/material";
import { RoundedContainer } from "./RoundedContainer";
import { HexColumn } from "./HexColumn";
import { HbA1cAxis } from "./HbA1cAxis";
import {
	greenHexColor,
	maxHbA1cValue,
	orangeHexColor,
	redHexColor,
} from "../constants";
import { Center } from "./Center";
import { InfoModal } from "./InfoModal";
import { ReactComponent as DiabetesMeterImage } from "../assets/images/diabetes-meter.svg";
import { useState } from "react";

export const HbA1c: React.FC<{ hbA1cValue: number }> = ({ hbA1cValue }) => {
	const maxColumns = 48;
	const coloredColumns = (hbA1cValue / maxHbA1cValue) * maxColumns;
	const [showModal, setShowModal] = useState<boolean>(false);
	return (
		<RoundedContainer onMoreButtonClicked={() => setShowModal(!showModal)}>
			<InfoModal show={showModal}>
				<Typography
					variant="h3"
					sx={{ textAlign: "center", fontWeight: 700, fontSize: "1.1rem" }}
				>
					Did You Know?
				</Typography>
				<Box height="5px" />
				<Typography
					sx={{ textAlign: "center", lineHeight: "16px", fontWeight: 500 }}
				>
					You can be tested for diabetes at our office?
				</Typography>
				<Box height="15px" />
				<Center>
					<DiabetesMeterImage />
				</Center>
				<Box height="30px" />
				<Typography sx={{ textAlign: "center", lineHeight: "16px" }}>
					Gum disease may cause your blood sugar levels to rise, which makes
					diabetes more difficult to manage.
				</Typography>
				<Box height="10px" />
				<Typography sx={{ textAlign: "center", fontWeight: 600 }}>
					Ask our team for more information.
				</Typography>
			</InfoModal>
			<Box display="flex" alignItems={"center"}>
				<Typography variant="h2">HbA1c</Typography>
				<Box width="20px" />
				<Typography fontWeight={500}>3 days ago</Typography>
			</Box>
			<Box height="25px" />
			{/* 9 Row, 49 column Hex Graph */}
			<Box display="flex">
				{Array.from(Array(maxColumns), (_, index: number) => {
					// Each HexColumn has 9 rows
					if (index >= 0 && index <= 15 && index < coloredColumns) {
						return <HexColumn color={greenHexColor} key={index} />;
					}

					if (index > 15 && index <= 31 && index < coloredColumns) {
						return <HexColumn color={orangeHexColor} key={index} />;
					}

					if (index > 31 && index <= maxColumns && index < coloredColumns) {
						return <HexColumn color={redHexColor} key={index} />;
					}

					return <HexColumn key={index} />;
				})}
			</Box>
			<Box height="30px"></Box>
			<HbA1cAxis hbA1cValue={hbA1cValue} />
		</RoundedContainer>
	);
};
