import { Box, Button, Typography } from "@mui/material";
import VirusImage from "../assets/images/virus.png";
import GenesImage from "../assets/images/genes.png";
import { Fragment, useState } from "react";

interface RiskButton {
	active: boolean;
	title: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const MyPerioPathTopBar: React.FC = () => {
	const [buttons, setButtons] = useState<RiskButton[]>([
		{
			active: true,
			title: "High",
		},
		{
			active: false,
			title: "Moderate",
		},
		{
			active: false,
			title: "Low",
		},
	]);
	const activeStyles: React.CSSProperties = {
		backgroundColor: "#384CFF !important",
		color: "#ffffff !important",
		border: "none !important",
	};

	// Handles setting the active attribute for each button when clicked
	const setActiveButton = (index: number) => {
		const copyOfButtons = buttons.map((button: RiskButton) => {
			return {
				...button,
				active: false,
			};
		});

		const newButton: RiskButton = {
			...copyOfButtons[index],
			active: true,
		};
		copyOfButtons[index] = newButton;
		setButtons(copyOfButtons);
	};

	return (
		<Box sx={{ display: "flex", justifyContent: "center" }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img src={VirusImage} alt="Virus" />
				<Box width={"5px"} />
				<Typography variant="h3" fontWeight={400}>
					Pathogens Risk
				</Typography>
				<Box width={"8px"} />
				{buttons.map((button: RiskButton, index: number) => {
					const { title, active, onClick } = button;
					const isLastIndex = index === buttons.length - 1;
					return (
						<Fragment key={index}>
							<Button
								onClick={(
									event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
								) => {
									setActiveButton(index);
									if (onClick !== undefined) {
										onClick(event);
									}
								}}
								sx={active ? activeStyles : undefined}
								variant="outlined"
							>
								{title}
							</Button>
							{!isLastIndex && <Box width={"5px"} />}
						</Fragment>
					);
				})}
			</Box>
			<Box width="100px" />
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img src={GenesImage} alt="Genes" />
				<Box width={"5px"} />
				<Typography variant="h3" fontWeight={400}>
					Gene Marker:
				</Typography>
				<Box width="3px" />
				<Typography variant="h3" fontWeight={"bold"}>
					Interieukin
				</Typography>
				<Box width="10px" />
				<Typography variant="h3" fontWeight={400}>
					Your Risk:
				</Typography>
				<Box width="3px" />
				<Typography sx={{ color: "#FF0000" }} variant="h3" fontWeight={"bold"}>
					High
				</Typography>
			</Box>
		</Box>
	);
};
