import { Box, Skeleton, SxProps, Theme } from "@mui/material";
import React, { useState } from "react";

export const ImagePlaceHolder: React.FC<{
	alt: string;
	src: string | undefined;
	sx?: SxProps<Theme> | undefined;
	onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
	widthLoading: string | number;
	heightLoading: string | number;
	variant?: "text" | "rectangular" | "rounded" | "circular" | undefined;
}> = ({ alt, src, sx, onClick, widthLoading, heightLoading, variant }) => {
	const [loaded, setLoaded] = useState<boolean>(false);

	return (
		<>
			<Box
				component={"img"}
				onLoad={() => {
					setLoaded(true);
				}}
				onClick={onClick}
				sx={{ display: loaded ? "block" : "none", ...sx }}
				alt={alt}
				src={src}
			></Box>

			{!loaded && (
				<Skeleton
					animation="wave"
					variant={variant ?? "rectangular"}
					width={widthLoading}
					height={heightLoading}
				/>
			)}
		</>
	);
};
