import { Box, Typography } from "@mui/material";

export const PerioPathYAxis: React.FC = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			{[...Array(7).keys()].reverse().map((num, index) => {
				return (
					<Typography key={index} sx={{ fontSize: "1.3rem" }} variant="h3">
						10<sup>{num + 1}</sup>
					</Typography>
				);
			})}
			<Box sx={{ height: "21.6%" }}>
				<Typography sx={{ fontSize: "1.3rem" }} variant="h3">
					LQ
				</Typography>
			</Box>
		</Box>
	);
};
