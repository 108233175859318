import React from "react";
import { VitalSignsItem } from "./VitalSignsItem";
import { ReactComponent as HeartRateGraphImage } from "../assets/images/heart-rate-graph.svg";
import { ReactComponent as HeartRateGraphDotImage } from "../assets/images/heart-rate-graph-dot.svg";

/**
 * The component that displays the heart rate of the patient
 *
 * @visibleName HeartRateVitalSignsItem
 */
export const HeartRateVitalSignsItem: React.FC = () => {
	const heartRateGraphDotStyles: React.CSSProperties = {
		position: "absolute",
		zIndex: 2,
	};

	return (
		<VitalSignsItem
			sx={{ position: "relative", overflow: "hidden" }}
			title={"Heart Rate"}
			subtitle={"128"}
		>
			<HeartRateGraphImage
				style={{ bottom: 0, left: 0, right: 0, position: "absolute" }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "54%", left: "1%", ...heartRateGraphDotStyles }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "65%", left: "17%", ...heartRateGraphDotStyles }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "66%", left: "33.4%", ...heartRateGraphDotStyles }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "83%", left: "52.4%", ...heartRateGraphDotStyles }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "83%", left: "70.4%", ...heartRateGraphDotStyles }}
			/>
			<HeartRateGraphDotImage
				style={{ top: "52%", right: "-2%", ...heartRateGraphDotStyles }}
			/>
		</VitalSignsItem>
	);
};
