import { v4 as uuid } from "uuid";
import { Page } from "./models/Page";
import { PatientOralDNA } from "./models/PatientOralDNA";
import { PatientVitals } from "./models/PatientVitals";
import { Home } from "./screens/Home";
import { Patients } from "./screens/Patients";
import { PatientDetail } from "./screens/PatientDetail";
import { PatientInfo } from "./models/PatientInfo";
import { PerioPathDataPoint } from "./models/PerioPathDataPoint";
import { NumberHelper } from "./utils/NumberHelper";
import { Ammp8BarValue } from "./models/Ammp8BarValue";

// Hex graph colors
export const greenHexColor = "#24CA49";
export const orangeHexColor = "#FF7A30";
export const redHexColor = "#F84439";

/// Ammp-8 colors
export const ammp8SeriesColors1 = "rgba(222, 56, 48, 1)"; // red
export const ammp8SeriesColors2 = "rgba(254, 126, 9, 1)"; // orange
export const ammp8SeriesColors3 = "rgba(36, 202, 73, 1)"; // green

/// Perio path series colors
export const perioPathSeriesColors1 = "#8676FF";
export const perioPathSeriesColors2 = "#0BDB2C";
export const perioPathSeriesColors3 = "#FFB443";
export const perioPathSeriesColors4 = "#DE3830";

/// Max values
export const maxHbA1cValue = 15;

// The menu pages
export const menuPages: Page[] = [
	{
		id: uuid(),
		name: "PatientList",
		path: "/",
		element: <Patients />,
	},
	{
		id: uuid(),
		name: "Home",
		path: "/sherpa-dashboard",
		element: <Home />,
	},
	{
		id: uuid(),
		name: "Patients",
		path: "/patients",
		element: <Patients />,
	},
	{
		id: uuid(),
		name: "PatientDetail",
		path: "/patientDetail",
		element: <PatientDetail />,
	},
];

/// The pages to show details of objects
export const detailPages: Page[] = [
	{
		id: uuid(),
		name: "",
		path: "/post/:id",
		element: <div></div>,
	},
];

/// Border radius
export const containerBorderRadius = "5px";

/// Spacing
export const defaultColumnAndRowGridSpacing = "30px";
export const sectionSpacing = "60px";

/// Api timeout
export const timeout = 10000;

export const samplePatientInfos: PatientInfo[] = [
	{
		serviceAreaId: "14113",
		mrn: "234",
		firstName: "Stephen",
		lastName: "Thorne IV",
		middleName: "",
		nextAppointment: new Date(),
		age: 35,
		weight: "180",
		gender: "Male",
		height: 60,
		patientId: "111111",
		doB: new Date(),
	},
	{
		serviceAreaId: "14113",
		mrn: "324",
		firstName: "Scott",
		lastName: "McCain",
		middleName: "",
		nextAppointment: new Date(),
		age: 45,
		weight: "220",
		gender: "Male",
		height: 62,
		patientId: "2222222",
		doB: new Date(),
	},
];

export const samplePatientVitals: PatientVitals = {
	serviceAreaId: "14113",
	mrn: "234",
	bp: "114/72 mmHG",
	pulseRate: 81,
	respirations: 20,
	temperature: "97.6",
	hbA1c: 6.2,
	perioFitness: 35,
};

export const samplePatientOralDNA1: PatientOralDNA = {
	serviceAreaId: "14113",
	mrn: "234",
	serviceDate: new Date(),
	resultDate: new Date(),
	aa: Math.pow(10, 6.5),
	pg: Math.pow(10, 6.74),
	tf: Math.pow(10, 6.8),
	td: Math.pow(10, 6.8),
	en: Math.pow(10, 6.9),
	pi: Math.pow(10, 6.7),
	cr: Math.pow(10, 6.4),
	pm: Math.pow(10, 6.5),
	ec: Math.pow(10, 6.8),
	cs: Math.pow(10, 6.5),
};

export const samplePatientOralDNA2: PatientOralDNA = {
	serviceAreaId: "14113",
	mrn: "234",
	serviceDate: new Date(),
	resultDate: new Date(),
	pg: Math.pow(10, 6.8),
	tf: Math.pow(10, 6.4),
	td: Math.pow(10, 6.5),
	en: Math.pow(10, 6.7),
	fn: Math.pow(10, 6.9),
	pi: Math.pow(10, 6.6),
	cr: Math.pow(10, 6.7),
	pm: Math.pow(10, 6.9),
	ec: Math.pow(10, 6.75),
	cs: Math.pow(10, 6.7),
};

export const samplePerioPathData: PerioPathDataPoint[] = [
	{
		date: "09/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "10/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "11/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "12/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "1/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "2/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "3/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "4/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "5/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "6/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
	{
		date: "7/20",
		aa: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		pg: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		tf: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
		td: Math.pow(10, NumberHelper.getRandomArbitrary(6, 6.8)),
	},
];

export const sampleHbA1cBarChartData: PerioPathDataPoint[] = [
	{
		date: new Date().toDateString(),
		aa: 63,
		pg: 56,
		tf: 49,
		td: 50,
	},
];

export const perioPathLimit = Math.pow(10, 7);

export const sampleAmmp8BarData: Ammp8BarValue[] = [
	{
		value: 23,
		date: "06/21",
	},
	{
		value: 34,
		date: "12/21",
	},
	{
		value: 38,
		date: "01/22",
	},
	{
		value: 43,
		date: "03/22",
	},
	{
		value: 31,
		date: "05/22",
	},
];

// export const samplePerioPathDataSet = [
// 	{
// 		date: "09/20",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},

// 	{
// 		date: "10/20",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "11/20",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "12/20",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "01/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "02/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "03/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "04/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "05/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "06/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// 	{
// 		date: "07/21",
// 		value: Math.pow(10, NumberHelper.getRandomArbitrary(0, 6.8)),
// 	},
// ];
