import { Box } from "@mui/material";
import React from "react";
import { CurvedDottedLineProps } from "../componentProps/CurvedDottedLinesProps";
import { Fragment } from "react";

export const CurvedDottedLine: React.FC<CurvedDottedLineProps> = ({
	color,
	length,
	sx,
}) => {
	const createPillShape = (color: string): React.ReactNode => {
		return (
			<Box
				sx={{
					width: "17px",
					height: "3px",
					opacity: 0.5,
					borderRadius: "500px",
					backgroundColor: color,
				}}
			/>
		);
	};

	return (
		<Box sx={{ display: "flex", ...sx }}>
			{[...Array(length).keys()].map((_, index) => {
				const lastIndex = 52;
				return (
					<Fragment key={index}>
						{createPillShape(color)}
						{index !== lastIndex && <Box width="5px" />}
					</Fragment>
				);
			})}
		</Box>
	);
};
