import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PatientsBackgroundImage from "../assets/images/patients-background.png";
import { Loading } from "../components/Loading";
import { PatientSelectionBox } from "../components/PatientSelectionBox";
import { getConfig } from "../config";
import { samplePatientInfos } from "../constants";
import { PatientInfo } from "../models/PatientInfo";

import api from "../services/APIService";

const { getPatients } = api;

export const Patients: React.FC = () => {
	const [queryParameters] = useSearchParams();

	const [patients, setPatients] = useState<PatientInfo[]>([]);
	const [apiLoading, setApiLoading] = useState(false);

	const navigate = useNavigate();

	const { getAccessTokenSilently } = useAuth0();

	const { apiOrigin } = getConfig();

	useEffect(() => {
		setApiLoading(true);

		const loadPatientsWithToken = async () => {
			var token = await getAccessTokenSilently();

			const serviceAreaId = queryParameters.get("serviceAreaId") ?? "0";

			const response = await getPatients(token, serviceAreaId);
			console.log("response: ", response);

			return response;
		};

		loadPatientsWithToken()
			.then((patients: PatientInfo[]) => {
				setPatients(patients);
				setApiLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setPatients(samplePatientInfos);
				setApiLoading(false);
			});
	}, [apiOrigin, getAccessTokenSilently]);

	/**
	 * Handles what occurs after a patient seletion box is clicked on
	 * @param  {number} patientIndex The index of the patient that has been selected
	 * @return {void}
	 */
	const handlePatientSelectionBoxClick = (patientIndex: number): void => {
		// Navigate to the patient detail screen and
		// pass patient object to it
		navigate("/patientDetail", {
			state: {
				patient: patients[patientIndex],
			},
		});
	};

	return apiLoading ? (
		<Loading />
	) : (
		<Box sx={{ position: "relative" }}>
			<Box
				sx={{ width: "100%", height: "auto" }}
				component={"img"}
				alt="Patients Background"
				src={PatientsBackgroundImage}
			/>

			<Box
				sx={{
					width: "7.7%",
					height: "56.4%",
					position: "absolute",
					zIndex: 2,
					top: "23.4%",
					left: "17.6%",
				}}
			>
				<Box height="52%"></Box>
				{patients.map((patient: PatientInfo, index: number) => {
					return (
						<PatientSelectionBox
							key={index}
							onClick={() => {
								handlePatientSelectionBoxClick(index);
							}}
							patient={patient}
						/>
					);
				})}
			</Box>
		</Box>
	);
};
