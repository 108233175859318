import { Box, Typography } from "@mui/material";
import React from "react";
import { SideBarMenuItemProps } from "../componentProps/SideBarMenuItemProps";

export const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
	active,
	icon,
	title,
	sx,
	onClick,
}) => {
	const generateActiveMarker = (active: boolean): React.ReactNode => {
		return (
			<Box sx={{ opacity: active ? 1 : 0, transition: "opacity 0.2s" }}>
				<Box
					sx={{
						display: "inline-block",
						width: "60px",
						rotate: "-90deg",
						position: "absolute",
						left: -31,
						top: -7,
					}}
					component={"svg"}
					viewBox="0 0 10 5"
				>
					<Box
						component={"path"}
						fill="#E2E2EA"
						d="M10 0 C7.5 0 7.5 5 5 5 S2.5 0 0 0z"
					/>
				</Box>
				<Box
					sx={{
						height: "18px",
						width: "18px",
						backgroundColor: "#0062FF",
						borderRadius: "50%",
						display: "inline-block",
						position: "absolute",
						left: -13,
						top: -1,
					}}
				></Box>
			</Box>
		);
	};

	return (
		<Box
			onClick={onClick}
			sx={{
				position: "relative",
				alignItems: "center",
				cursor: "pointer",
				...sx,
			}}
			display={"flex"}
		>
			{generateActiveMarker(active)}
			{active && <Box width="30px"></Box>}
			{icon}
			<Box width="10px"></Box>
			<Typography
				fontSize={active ? "0.9rem" : "12px"}
				fontWeight={500}
				color={"#000"}
			>
				{title}
			</Typography>
		</Box>
	);
};
