import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { detailPages, menuPages } from "../constants";
import { Page } from "../models/Page";
import PageNotFound from "../screens/PageNotFound";
import { sherpaTheme } from "../theme";
import ScrollToTop from "./ScrollToTop";
import { v4 as uuid } from "uuid";
import ProtectedRoute from "./ProtectedRoute";
import { Loading } from "./Loading";
import { useAuth0 } from "@auth0/auth0-react";

export const App = () => {
	const { isLoading } = useAuth0();

	return isLoading ? (
		<Loading />
	) : (
		<>
			<ThemeProvider theme={sherpaTheme}>
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						{menuPages.map((page: Page) => {
							return (
								<Route
									key={page.id}
									path={page.path}
									element={<ProtectedRoute>{page.element}</ProtectedRoute>}
								/>
							);
						})}
						{detailPages.map((page: Page) => {
							return (
								<Route
									key={page.id}
									path={page.path}
									element={<ProtectedRoute>{page.element}</ProtectedRoute>}
								/>
							);
						})}
						<Route key={uuid()} path="*" element={<PageNotFound />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
};
