import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

/**
 * Allows us to center any children vertically and horizontally placed within this component
 *
 * @visibleName Center
 */
export const Center: React.FC<{
	children?: React.ReactNode;
	sx?: SxProps<Theme> | undefined;
}> = ({ children, sx }) => {
	return (
		<Box
			sx={sx}
			display={"flex"}
			flexDirection="column"
			justifyContent="center"
			alignItems={"center"}
		>
			{children}
		</Box>
	);
};
