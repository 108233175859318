import { Box } from "@mui/material"
import "./Loading.scss"

export const Loading = () => {

    return (
        <Box className="spinner5">
        </Box>
    );
}

